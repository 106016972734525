@media only screen and (min-width: 968px) {
    .footer1 {
        text-align: center;
        grid-template-columns: repeat(12, minmax(0, 1fr));
        display: grid;
    }
}

@media (max-width:320px) {
    .footer1 p{
      display: flex;
      margin-top: 10px;
      margin-left: 13px;
      margin-bottom: 20px;
    }
    .footer-text {
        z-index: 2;
        margin-left: -20%;
    }
    .subscription-form {
        width: 600px;
        display: block !important;
    }
    .box-css {
        display: none;
    }
    .include-box {
        display: flex !important;
    }
    .faq-content {
        margin-left: -3%;
        margin-right: -3%;
    }
    .title-text {
        margin-left: 5% !important;
    }
}

@media (max-width:520px) {
    .subscription-form {
        width: 240%;
        display: block !important;
    }
}

@media (min-width:321px) and (max-width:968px) {
    .footer1 p{
      display: flex;
      margin-top: 10px;
      margin-left: 5%;
      margin-bottom: 20px;
    }
    .box-css {
        display: none;
    }
    .footer-text {
        z-index: 2;
        margin-left: 0%;
    }
}

.page-height {
    /*height: 100vh !important;*/
    min-height: 100vh;
    background-color: black !important;
}