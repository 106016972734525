.color-link-dark {
  color: rgb(65 131 238);
}
.color-link {
  /* Same as above but lighter */
  color: rgb(108 159 233);
}

.video {
  position: relative;
  z-index: 10;
  transform: translateY(80px);
  width:70%;
  margin:auto;
}
.main {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)),
    url("../assets/images/WordGalaxy.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  /*opacity:0.8;*/
}
.box1 {
  width: 15rem;
  height: 15rem;
  background-color: #fff2c8;
  border-radius: 20px;
  position: absolute;
  left: 30px;
  bottom: -15px;
  transform: rotate(65deg);
  z-index: 0;
}
.about-box1 {
  width: 12rem;
  height: 12rem;
  background-color: #fff2c8;
  border-radius: 50%;
  position: absolute;
  left: -90px;
  bottom: -45px;
  transform: rotate(65deg);
  z-index: 0;
}
.about-box2 {
  width: 12rem;
  height: 12rem;
  background-color: #d2e8ff;
  border-radius: 50%;
  position: absolute;
  top: -150px;
  right: -19px;
  z-index: 1;
}
.about-box2::before {
  content: "";
  width: 50px;
  height: 50px;
  background-color: #6a67ff;
  border-radius: 50%;
  position: absolute;
  top: 5px;
  left: 5px;
}
.seo-box {
  width: 10rem;
  height: 10rem;
  background-color: #fab6ad;
  border-radius: 18px;
  position: absolute;
  left: 22rem;
  top: -45px;
  transform: rotate(-65deg);
  z-index: -1;
}

.box2 {
  width: 15rem;
  height: 15rem;
  background-color: #d2e8ff;
  border-radius: 50%;
  position: absolute;
  top: -8px;
  right: 47px;
  z-index: 1;
}
.container {
  width: 85%;
}

/* .video::before {
  content: "";
  width: 150px;
  height: 50px;
  background-color: #fff;
  position: absolute;
  left: -151px;
  bottom: 30px;
} */

.SEO-section::after {
  content: "";
  width: 15rem;
  height: 15rem;
  background-color: #fff2c8;
  border-radius: 50%;
  position: absolute;
  top: -8rem;
  right: -80px;
  z-index: 1;
}
.seo-card {
  min-height: 27vh;
}

.pricing::before {
  content: "";
  width: 15rem;
  height: 15rem;
  background-color: #ffe6df;
  border-radius: 20px;
  position: absolute;
  left: -20px;
  top: -125px;
  transform: rotate(30deg);
  z-index: -1;
}

.appfooter {
  position: relative;
}
.FAQSection {
  position: relative;
}
.FAQSection::before {
  content: "";
  width: 15rem;
  height: 15rem;
  background-color: #d2e8ff;
  border-radius: 20px;
  position: absolute;
  right: -11.5%;
  bottom: -16%;
  transform: rotate(60deg);
  z-index: 999;
}

.appfooter::after {
  content: "";
  width: 15rem;
  height: 15rem;
  background-color: #fff2c8;
  border-radius: 50%;
  position: absolute;
  left: -8rem;
  bottom: -9rem;
  z-index: 1;
}

[type="text"]:focus {
  box-shadow: none !important;
}

.text-lightgray { color: #ababab; }

.bg-darkBlue.appfooter {background-color:#2B5592;}
.bg-darkBlue.main .bg-white{background-color:#000000 !important;}
.transition h2,.transition p, .appfooter ul li{cursor: default !important;}
.bg-darkBlue.main .bg-white ul li span{cursor: pointer;}

.bg-white{background-color:#ffffff;}
.bg-bluebutton{background-color:#0071CE;}
.bg-graytitle{background-color:#2d2d2d !important;}
.bg-blacktitle{background-color:#2c2c2c !important;}
.bg-blacklight{background-color:#212121 !important;}
.bg-blackmedium{background-color:#1a1a1a !important;}
.bg-blackdark{background-color:#121212 !important;}

.bg-lightBluebtn {background-color:#0071CE !important;border:none;}
/*.bg-lightBluebtn:hover {background-color:#2c5cc4 !important; color: #fff;}*/
.bg-lightBluebtn:hover {background-color:#2d56ad !important; color: #fff;}
#features {background-color:#2B5592;}

.featurebtn {background-color:#0071CE;}
.featurebtn:hover {background-color:#0071CE;color: #000000;}
.footer-text{color: #C4D6ED;}

.footer-text1 {width: 100% !important;color: #C4D6ED;text-align: center;vertical-align: middle; margin-top: 30px;margin-right: 30px;}
.logo_img{width:100%;height:auto; margin:auto;}
.logo_img_sec{text-align:center;}
.add_padding{padding-left:6px;}
.dsktop_menu{display:none;}
.login_form{width:80%; margin:0 auto;}
.py-1.text-gray-700 .py-1 .text-gray-700{color:#0071ce !important;}
[type='text']:focus, [type='email']:focus, [type='url']:focus, [type='password']:focus, [type='number']:focus, [type='date']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='week']:focus, [multiple]:focus, textarea:focus, select:focus{
  box-shadow:none;
}
.dash_subscription_sec{min-height:500px;}
@media (min-width: 768px) {
  .main_logo{position:fixed;left:0;top:7px;height:4rem;}
  .ver_link_features{color:#FFF;position:fixed;left:-11px;width:127px;top:121px;padding:10px 15px;background:#000;z-index:99;margin-left:3vw;}
  .ver_link_pricing{color:#FFF;position:fixed;left:-11px;width:127px;top:80px;padding:10px 15px;background:#000;z-index:99;margin-left:3vw;}
  .ver_link_about_us{color:#FFF;position:fixed;left:-11px;width:127px;top:162px;padding:10px 15px;background:#000;z-index:99;margin-left:3vw;}
  .ver_link_affiliates{color:#FFF;position:fixed;left:-11px;width:127px;top:203px;padding:10px 15px;background:#000;z-index:99;margin-left:3vw;}
  .ver_link_contact_us{color:#FFF;position:fixed;left:-11px;width:127px;top:244px;padding:10px 15px;background:#000;z-index:99;margin-left:3vw;}
  .ver_link_features:hover span{color:#0071CE !important;}
  .ver_link_pricing:hover span{color:#0071CE;}
  .ver_link_about_us:hover span{color:#0071CE;}
  .ver_link_contact_us:hover span{color:#0071CE;}
  .signupbanner{width:auto;}
  .dsktop_menu{display:block;position:fixed; top: 79px;left: -14px;height: 208px;width: 140px;background: #000;z-index: 99;border:1px solid #FFF;margin-left:3vw;}
}

.ver_link_affiliates:hover {
  color: rgb(4,111,193);
}

@media (max-width: 390px) {
  .box1{
    width: 10rem;
    height: 10rem;
  }
  .box2 {
    width: 10rem;
    height: 10rem;
    top: 19px;
    right: -27px;
  }
}

@media (max-width: 820px) {
  .seo-card {
    min-height: 18vh;
  }
}

/*For wysiwyg editor*/
.rdw-link-decorator-wrapper {
  color: blue;
  /*text-decoration: underline;*/
}

.gen-simple {
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  color: black;
  align-items: center;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.m2 {
  margin: 2vmin;
}

.m4 {
  margin: 4vmin;
}

.qaw-right {
  align-items: flex-end;
  white-space: nowrap;
}

.gen-content {
  align-items: center;
}

.article-row {
  cursor: pointer;
}

/*.article-row:hover {
  background-color: #3c3c3c;
  color: white !important;
}*/



.ellipsis {
  color: black;
  display: inline-block;
  animation: ellipsis 1.5s infinite;
}

@keyframes ellipsis {
  0% {
    opacity: 0;
    /*width: 0;*/
  }
  25% {
    opacity: 1;
    /*width: 25%;*/
  }
  50% {
    opacity: 1;
    /*width: 50%;*/
  }
  75% {
    opacity: 1;
    /*width: 75%;*/
  }
  100% {
    opacity: 0;
    /*width: 100%;*/
  }
}

::-webkit-scrollbar {
    height: 12px;
    width: 12px;
    background: #2e2e2e;
}

::-webkit-scrollbar-thumb {
    background: #3b3b3b;
    -webkit-border-radius: 1ex;
    -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
}
