
.loader-circle {
    width: 40px;
    height: 40px;
    /*border: 5px solid #cacaca;*/
    border: 8px solid #0071CE;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1.2s linear infinite;
}

@keyframes rotation {
0% {
    transform: rotate(0deg);
}
100% {
    transform: rotate(360deg);
}
} 