.plans-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* justify-content: center;
    align-items: center; */
    gap: 30px;
    padding-top: 30px;
  }
  
  .plan {
    padding: 30px;
    /* text-align: center; */
    width: 40%;
    height: 30% !important;
  }

    .amount {
      font-size: 40px;
      font-weight: bold;
    }

    .interval {
      width: min-content;
      display: inline-block;
      color: darkgray;
      font-size: medium;
      padding: 2px;
    }

    .subscribe-btn {
      background-color: #0074d4;
      width: 100%;
    }

    .include-container {
      color: #000;
    }
  
    .text-white {
      color: white;
    }

    .text-title {
      font-size: 30px;
    }
     
  .individual-plan {
    border-radius: 15px;
    background-color: #f2f2f2;
    border: 1px solid #00000038;
    /* color: white; */
  }

  .dashboard-plan {
    border-radius: 15px;
    background-color: #f2f2f2;
    border: 1px solid #00000038;

  }

  .current-plan {
    /* display: flex; */
    /* flex-direction: column; */
    /* align-items: center; */
    /* text-align:center; */
    padding: 8px 12px;
    font-family: -apple-system, BlinkMacSystemFont, 'Roboto', sans-serif;
    border-radius: 6px;
    border: none;
    /* width: 50%; */
    color: #000;
    /*background: linear-gradient(180deg, #4B91F7 0%, #367AF6 100%);*/
    background: linear-gradient(180deg, #fff517 0%, #ffd01f 100%);
    background-origin: border-box;
    /*box-shadow: rgba(45, 35, 66, 0.4) 0 8px 12px,rgba(45, 35, 66, 0.3) 0 7px 18px -3px,#D6D6E7 0 -3px 0 inset;*/
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }

  .cancel-plan {
    padding: 8px 12px;
    font-family: -apple-system, BlinkMacSystemFont, 'Roboto', sans-serif;
    border-radius: 6px;
    border: none;
    /* width: 50%; */
    background-color: #373737;
    background-origin: border-box;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }

  .plan h2 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #000;
    text-transform:capitalize;
  }
  
  .plan p {
    font-size: 18px;
    margin-bottom: 20px;
    color: #3b2b2b070;
  }
  
  .plan button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .plan button:hover {
    background-color: #0056b3;
  }

  .active-status {
    text-transform: capitalize;
  }


  .detail-title {
    font-size: large;
  }

  .subscription-table {
    margin-top: 10px;
    width: 100%;
  }
  
  .payment-table {
    margin-top: 10px;
    width: 100%;
  }

  .details-break {
    border-color: #454545;
    /*border-width: 2px;*/
  }