@tailwind base;
@tailwind components;
@tailwind utilities;

.dark-screen {
    /*background-color: #292929 !important;*/
    background-color: #1d1d1d !important;
}

.text-color-white {
    color: white !important;
}

.text-color-black {
    color: black !important;
}

.faq {
    color: white !important;

}

.faq:hover {
    color: black !important;
}

.style-text {
    margin-top: -5%;
 	color: white !important;
}

